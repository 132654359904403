import {
 Grid, OutlinedInputProps, TextField, Typography,
} from '@mui/material';
import { MappingParamType } from 'shared-scope/types';
import MappingParamCombobox, {
  MappingParamComboboxValue,
} from 'products/shared/Campaign/components/MappingParamCombobox';
// import MappingParamSelect from './MappingParamSelect';

export default function MappingParam<T>(
  {
    title,
    type,
    value,
    inputProps,
    inputWidth,
    onChange,
    helperText,
    multiple = false,
    canPaste = false,
    required = false,
  }: MappingParamProps<T>,
) {
  const mappingParamControl = (type && Array.isArray(type))
    ? <MappingParamCombobox
        value={Array.isArray(value) ? value as MappingParamComboboxValue[] : value as MappingParamComboboxValue}
        onChange={(v) => onChange(v as T)}
        options={type}
        multiple={multiple}
        canPaste={canPaste}
        inputWidth={inputWidth}
    />
    : <TextField
        value={value}
        type={type}
        onChange={(e) => onChange(e.target.value as unknown as T)}
        variant="outlined"
        InputProps={inputProps}
        sx={{ width: inputWidth || 'auto' }}
        required={required}
        helperText={helperText}
        onFocus={(e) => e.target.select()}
    />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} lg={4}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        {mappingParamControl}
      </Grid>
    </Grid>
  );
}

type MappingParamProps<T> = {
  title: string
  value: T
  type?: MappingParamType[] | string[] | string
  inputProps?: OutlinedInputProps
  inputWidth?: number | string
  onChange: (value: T) => void
  helperText?: string
  multiple?: boolean
  required?: boolean
  canPaste?: boolean
  nullable?: boolean
};
