import {
  DataGrid,
  ApiQueryParams,
  ApiQueryResult,
  useGridRef,
  useRequest,
  useMountedState,
} from '@xeebi/neru';
import { cloneDeep, get } from 'lodash';
import {
  Stack,
  IconButton,
} from '@mui/material';
import {
  GridRowModesModel,
  GridRowModes,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { UID } from 'shared-scope/helpers/functions';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  Region,
  Contact,
  PhoneBook as PhoneBookObj, SrcHashtag,
} from 'graphql-api';
import useAlert from 'shared-scope/hooks/useAlert';
import Struct from 'products/PhoneBook/PhoneBookGrid/Struct';
import Grid from '@mui/material/Grid';
import { getSdk } from 'products/PhoneBook/query.generated';
import { getSdk as commonSdk } from 'products/common/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import Imports from 'products/common/CampaignShared/Imports';
import { ImportType } from 'products/common/types';
import {
  createOrModifyContact,
  deleteContact,
  newContact,
  phoneNumberValidator,
} from './helpers';
import Header from './header';


const api = getSdk(fetcher);
const commonApi = commonSdk(fetcher);

export function usePhoneBookGridProps() {
  return useOutletContext<PhoneBookGridProps>();
}

export default function PhoneBookGrid() {
  const { onPhoneBookDelete } = usePhoneBookGridProps();
  const { id: phonebookId } = useParams();
  const isMounted = useMountedState();

  const [allHashtags, setAllHashtags] = useState<SrcHashtag[]>([]);
  const [phoneBook, setPhoneBook] = useState<PhoneBookObj | null>(null);
  const contactFilter = useRef<string>('null');
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState<Contact[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const gridRef = useGridRef();

  const { addError } = useAlert();

  const {
    error: loadBookError,
    fetch: loadPhoneBook,
  } = useRequest(commonApi.getPhoneBook);

  const {
    error: createError,
    fetch: create,
  } = useRequest(createOrModifyContact);

  const {
    error: deleteError,
    fetch: deleteContacts,
  } = useRequest(deleteContact);

  const {
    error: errorHashtags,
    fetch: fetchHashtags,
  } = useRequest(commonApi.getSrcHashtags);

  useEffect(() => {
    const loadHashtags = async () => {
      const { srcHashtag } = await fetchHashtags({ filter: `{"country.id": {"$in": [${phoneBook?.country?.id || -1}]}}` });
      isMounted() && setAllHashtags(srcHashtag as SrcHashtag[]);
    };
    isMounted() && phoneBook?.country?.id && loadHashtags();
  }, [isMounted, fetchHashtags, phoneBook]);

  useEffect(() => {
    const load = async () => {
      const data = await loadPhoneBook({
        filter: JSON.stringify({ id: Number(phonebookId) }),
      });
      setPhoneBook(data?.phoneBook?.[0] || null);
    };
    isMounted() && load();
  }, [loadPhoneBook, isMounted, phonebookId]);

  const deleteRecords = useCallback(async () => {
    await deleteContacts({
      phoneBookId: phoneBook?.id,
      contactIds: selected,
    });
    setSelected([]);
    gridRef.current.refresh();
  }, [deleteContacts, selected, gridRef, phoneBook?.id]);

  const processRowUpdate = useCallback((newRow: any) => {
    const save = async () => {
      const row = cloneDeep(newRow);
      // deleting the virtual column
      delete row.srcHashtags;
      if (row.isNew) {
        delete row.id;
      }
      delete row.isNew;

      await create({
        phoneBookId: phoneBook?.id,
        contact: row,
      });

      gridRef.current.refresh();
    };

    if (phoneBook?.id && newRow.phoneNumber) {
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [newRow.id]: { mode: GridRowModes.View },
      }));
      save();
    }

    return newRow;
  }, [create, gridRef, phoneBook?.id]);

  const handleSaveClick = useCallback((id) => {
    const editData = gridRef.current.unstable_getEditCellMeta(id, 'phoneNumber');
    const isValid = phoneNumberValidator(get(editData, 'value', ''));
    if (isValid) {
      setRowModesModel((oldModel) => {
        return {
          ...oldModel,
          [id]: { mode: GridRowModes.View },
        };
      });
    }
  }, [gridRef]);

  const handleCancelClick = useCallback((id) => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    gridRef.current.setRows([...rows]);
    gridRef.current.refresh();
  }, [rows, gridRef]);

  const columns = useMemo(() => {
    const col = Struct(allHashtags);
    col.push(
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        renderCell: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          return (
            <Stack direction="row">
              {isInEditMode
                ? (
                  <>
                    <IconButton
                      size="small"
                      sx={{
                        color: 'primary.main',
                      }}
                      onClick={() => handleSaveClick(id)}
                    >
                      <i className="icon-save" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleCancelClick(id)}
                    >
                      <i className="icon-close" />
                    </IconButton>
                  </>
                )
                : null}
            </Stack>
          );
        },
      },
    );
    return col;
  }, [handleCancelClick, handleSaveClick, rowModesModel, allHashtags]);

  const loadContacts = useCallback(async (params: ApiQueryParams) => {
    if (phoneBook) {
      contactFilter.current = params.filter;
      const { contactCount, contact } = await api.getContact({ ...params, phoneBookId: phoneBook.id || -1 });
      setCount(contactCount || 0);
      setRows(contact);
      return { count: contactCount, rows: contact } as ApiQueryResult;
    }
    contactFilter.current = 'null';
    return { count: 0, rows: [] } as ApiQueryResult;
  }, [phoneBook]);

  useEffect(() => {
    gridRef.current.refresh();
  }, [loadContacts, gridRef]);

  const addNewRow = useCallback(() => {
    const id = UID();
    const newRow = newContact(id);
    const newRows = [newRow, ...rows];
    gridRef.current.setRows(newRows);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'phoneNumber' },
    }));
  }, [gridRef, rows]);

  useEffect(() => {
    const error = createError || deleteError || loadBookError || errorHashtags;
    if (error) {
      addError(error.getMessage());
      console.error(error.getError());
    }
  }, [createError, addError, deleteError, errorHashtags, loadBookError]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Header phoneBook={phoneBook} onPhoneBookDelete={onPhoneBookDelete} count={count} />
      </Grid>

      {phoneBook?.id && (
        <Grid item xs={12}>
          <Imports
            type={ImportType.PhoneBook}
            dstId={phoneBook?.id}
            title="Imports"
            statusFilter={false}
            gridRef={gridRef}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <DataGrid
          storageId="PhoneBookGridStorage"
          pageSize={30}
          apiRef={gridRef}
          getRows={loadContacts}
          columns={columns}
          processRowUpdate={processRowUpdate}
          editMode="row"
          onCreate={addNewRow}
          createLabel="Add new contact"
          rowModesModel={rowModesModel}
          onRowModesModelChange={setRowModesModel}
          selected={selected}
          onSelect={setSelected}
          onDelete={deleteRecords}
          checkboxSelection
          hideFooterSelectedRowCount={false}
          nullRender="-"
        />
      </Grid>
    </Grid>
  );
}

export type PhoneBookGridProps = {
  regions: Region[]
  onPhoneBookDelete: () => void
};
