import React, {
  MutableRefObject,
} from 'react';
import { isEmpty } from 'lodash';
import {
  Stack,
} from '@mui/material';
import {
  GridColDef,
  GridHeaderFilterCellProps,
} from '@mui/x-data-grid-pro';
import { s } from 'i18n';
import { ConversationTemplate } from 'graphql-api';
import {
  CampaignETA,
  GridType,
} from 'products/CampaignList/types';
import { Keyword } from 'products/common/CampaignShared/components/Keyword';
import { Action } from 'products/CampaignList/components/Action';
import { FilterDropdown, GridRefType, FilterDateRange } from '@xeebi/neru';
import { StatusBadge } from 'products/CampaignList/components/StatusBadge';
import { getCampaignStatuses } from 'products/CampaignList/helpers';
import DateTime from 'shared-scope/components/DateTime';


type StructProps = {
  gridRef: MutableRefObject<GridRefType>
  templates: ConversationTemplate[]
};

export default function getColumns({ gridRef, templates }: StructProps) {
  const campaignStatuses = getCampaignStatuses(GridType.TextCampaign);
  return [
    {
      field: 'action',
      headerName: 'Action',
      renderCell: ({ row }) => (
        <Action
          row={row}
          onAction={(data: CampaignETA) => {
            if (!isEmpty(data)) {
              gridRef.current.updateRows([data]);
            }
            gridRef.current.refresh();
          }}
        />
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 0,
    },
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      minWidth: 100,
      disableColumnMenu: true,
      filterable: true,
      flex: 0,
    },
    {
      field: 'keywords',
      headerName: 'Keywords',
      type: 'string',
      sortable: true,
      filterable: true,
      disableColumnMenu: true,
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => (row?.keywords ? (
        <Stack direction="row" alignItems="center" spacing={1} whiteSpace="nowrap" sx={{ height: '100%' }}>
          {row?.keywords?.split(' ')?.map((keyword: string) => (
            <Keyword key={keyword} keyword={keyword} />
          ))}
        </Stack>
      ) : '-'),
    },
    {
      field: 'status',
      headerName: s('Status'),
      disableColumnMenu: true,
      type: 'custom',
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDropdown
          {...params}
          options={campaignStatuses}
          keyName="id"
          valueName="name"
          placeholder={s('Status')}
          minWidth={150}
        />
      ),
      renderCell: ({ row, value }) => (
        <StatusBadge
          row={row}
          value={value}
          gridType={GridType.TextCampaign}
          hideCounters
        />
      ),
      minWidth: 200,
      flex: 0,
    },
    {
      field: 'optInFirst',
      headerName: 'Opt-in first',
      type: 'number',
      minWidth: 100,
      disableColumnMenu: true,
      filterable: true,
      flex: 0,
    },
    {
      field: 'optInSecond',
      headerName: 'Opt-in second',
      type: 'number',
      minWidth: 100,
      disableColumnMenu: true,
      filterable: true,
      flex: 0,
    },
    {
      field: 'cMessagesStopList',
      headerName: 'Stopped',
      type: 'number',
      minWidth: 100,
      disableColumnMenu: true,
      filterable: true,
      flex: 0,
    },
    {
      field: 'conversationTemplateId',
      headerName: s('Template'),
      disableColumnMenu: true,
      type: 'custom',
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDropdown
          {...params}
          options={templates}
          keyName="id"
          valueName="name"
          placeholder={s('Template')}
          minWidth={150}
        />
      ),
      valueGetter: (value) => {
        const v: any = value;
        return v?.name || '-';
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'createTs',
      headerName: s('Created'),
      disableColumnMenu: true,
      type: 'custom',
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDateRange
          {...params}
          placeholder={s('Start date')}
        />
      ),
      valueGetter: (value) => (value ? new Date(value) : null),
      renderCell: ({ value }) => <DateTime value={value} direction="row" />,
      minWidth: 150,
      flex: 0,
    },
  ] as GridColDef[];
}
