import {
 Stack, Typography,
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { DataGridPro, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { gridStyles, formatNumber } from '@xeebi/neru';
import { s } from 'i18n';
import { isEqual, isEmpty } from 'lodash';
import {
 Sources, Mapping, MappingData, MappingFormValue,
} from '../types';
import {
  MAPPING_CAMPAIGN, MAPPING_PHONE_BOOK, MappingColumns,
  MappingColumnsTitles,
} from '../helpers/const';

const DO_NOT_IMPORT = 'do_not_import';
const ROW_COUNT = 15;

export const SORTED_COLUMN_FIELDS = [
  MappingColumns.COLUMN_PHONE,
  MappingColumns.COLUMN_FIRST_NAME,
  MappingColumns.COLUMN_LAST_NAME,
  MappingColumns.COLUMN_GENDER,
  MappingColumns.COLUMN_BIRTHDAY,
  MappingColumns.COLUMN_COUNTRY,
  MappingColumns.COLUMN_STATE,
  MappingColumns.COLUMN_TIMEZONE,
  MappingColumns.COLUMN_CUSTOM_1,
  MappingColumns.COLUMN_CUSTOM_2,
  MappingColumns.COLUMN_CUSTOM_3,
  MappingColumns.COLUMN_CUSTOM_4,
  MappingColumns.COLUMN_CUSTOM_5,
  MappingColumns.COLUMN_CUSTOM_6,
  MappingColumns.COLUMN_CUSTOM_7,
  MappingColumns.COLUMN_CUSTOM_8,
  MappingColumns.COLUMN_CUSTOM_9,
  MappingColumns.COLUMN_CUSTOM_10,
  MappingColumns.COLUMN_CLIENT_MESSAGE_ID,
  MappingColumns.COLUMN_TRACKING_ID,
];

export default function MappingTableReadOnly({
 data, mode, mappingParams, onChange: setMapping, value: mapping,
}: MappingTableProps) {
  const preparedData = true;

  useEffect(() => {
    let dfltMapping;
    switch (mode) {
      case Sources.phonebook:
        dfltMapping = MAPPING_PHONE_BOOK;
        break;
      case Sources.campaign:
        dfltMapping = MAPPING_CAMPAIGN;
        break;
      default:
        dfltMapping = {};
    }

    if (!isEmpty(mapping)) {
      const newMapping: Mapping = {};
      for (const key of Object.keys(mapping)) {
        newMapping[key] = dfltMapping[key];
      }
      dfltMapping = newMapping;
    }
    if (!isEqual(dfltMapping, mapping)) {
      setMapping(dfltMapping);
    }
  }, [mode, setMapping, mapping]);

  const renderHeader = useCallback(
    (params: GridColumnHeaderParams) => {
      let headerValue = DO_NOT_IMPORT;
      for (const k in mapping) {
        if (params.colDef.field === mapping[k]?.toString()) {
          headerValue = k;
          break;
        }
      }

      return <div>{MappingColumnsTitles[headerValue] || ''}</div>;
    },
    [mapping],
  );

  const rowset = useMemo(() => {
    if (!data) {
      return [];
    }
    const rows: any = [];
    let lines: any = [];
    if (preparedData) {
      lines = data.data_rowset || [];
    } else if (data.file_type === 'csv') {
      const delimiter = mappingParams.delimiter || ',';
      const quoteCharacter = mappingParams.quoteCharacter || '"';
      for (const line of data.data_lines?.split('\n') || []) {
        const newLine: string[] = [];
        for (const v of line.split(delimiter)) {
          if (v.startsWith(quoteCharacter) && v.endsWith(quoteCharacter)) {
            newLine.push(v.substring(1, v.length - 1));
          } else {
            newLine.push(v);
          }
        }
        lines.push(newLine);
      }
    } else if (data.file_type === 'excel') {
      const sheet = mappingParams.sheet || 0;
      lines = data.data_sheets?.[sheet] || [];
    }

    const skipTopRows = +(mappingParams.skipTopRows || 0);
    const cutDigits = mappingParams.cutDigits || 0;
    const addPrefix = mappingParams.addPrefix || '';
    for (let j = skipTopRows; j < lines.length; j += 1) {
      const row: any = { id: j };
      for (let i = 0; i < lines[j].length; i += 1) {
        row[i] = lines[j][i];
      }
      if (
        mapping.phone_number !== undefined
        && mapping.phone_number !== null
        && (!!cutDigits || !!addPrefix)
      ) {
        let v = row[mapping.phone_number];
        if (cutDigits) {
          v = v.substring(cutDigits);
        }
        if (addPrefix) {
          v = `${addPrefix}${v}`;
        }
        row[mapping.phone_number] = v;
      }
      rows.push(row);
    }
    return rows.splice(0, ROW_COUNT);
  }, [data, mappingParams, mapping.phone_number, preparedData]);

  const columns = useMemo(
    () => {
      const newColumns: Record<string, GridColDef> = {};
      const result: GridColDef[] = [];

      const availColumns = Object.keys(mapping)
        .filter((k) => !['address_id', 'contact_id'].includes(k))
        .map((k) => String(mapping[k]));

      for (const row of rowset) {
        for (const fieldName of Object.keys(row)) {
          if (!(fieldName === 'id' || newColumns[fieldName]) && availColumns.includes(fieldName)) {
            newColumns[fieldName] = {
              field: fieldName,
              headerName: fieldName,
              width: 200,
              hideSortIcons: true,
              renderHeader,
              sortable: false,
              filterable: false,
            };
          }
        }
      }

      let keys = Object.keys(newColumns);

      switch (mode) {
        case Sources.campaign:
          keys = SORTED_COLUMN_FIELDS
            .map((k) => String(MAPPING_CAMPAIGN[k]))
            .filter((k) => k in newColumns);
          break;
        case Sources.phonebook:
          keys = SORTED_COLUMN_FIELDS
            .map((k) => String(MAPPING_PHONE_BOOK[k]))
            .filter((k) => k in newColumns);
          break;
        default:
          keys = keys.sort(
            (a, b) => (parseInt(a, 10) < parseInt(b, 10) ? -1 : 1),
          );
      }

      for (const k of keys) {
        result.push(newColumns[k]);
      }
      return result;
    },
    [rowset, renderHeader, mapping, mode],
  );

  return columns ? (
    <Stack spacing={1}>
      {((data.data_count || 0) && mode === Sources.phonebook)
        ? <Typography variant="h4">
          {s(
            'Contacts: :cnt',
            { cnt: formatNumber({ value: data.data_count || 0 }) },
          )}
        </Typography>
        : null}
      <DataGridPro
        rows={rowset}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnReorder
        disableChildrenSorting
        disableColumnPinning
        disableColumnSelector
        disableMultipleColumnsSorting
        hideFooter
        sx={gridStyles}
      />
    </Stack>
  ) : null;
}

type MappingTableProps = {
  mode?: Sources
  data: MappingData
  mappingColumns?: Mapping
  mappingParams: MappingFormValue
  value: Mapping
  onChange: (v: Mapping) => void
};

