import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import moment from 'moment-timezone';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Typography,
  DialogActions,
  IconButton,
  Switch,
  Stack,
} from '@mui/material';
import { s } from 'i18n';
import { useAppContext } from 'shared-scope/AppContext';
import Loading from 'shared-scope/components/Loading';
import {
  PERMISSION_GENERAL,
  PERMISSION_CHATBOT,
  hasPermissionById,
} from 'permission';
import { fetcher } from 'graphql-api/fetcher';
import {
  TermsConditionsUser,
  TermsConditionsUserInput,
  TermsConditions,
  Campaign,
} from 'graphql-api';
import showdown from 'showdown';
import { getSdk } from 'products/common/queries.generated';
import styles from './TermsConditions.module.scss';


const api = getSdk(fetcher);

type TermsProps = {
  tcUser: TermsConditionsUser | null
  tc: TermsConditions
  tcCampaign?: Campaign | null
  onClose?: () => void | false
  onAccept?: () => void | false
};


export default function Terms({
  tcUser,
  tc,
  tcCampaign,
  onClose = () => false,
  onAccept = () => false,
}: TermsProps) {
  const appContext = useAppContext();
  const [error, setError] = useState<string>('');
  const [tcText, setTcText] = useState<string>('');
  const textRef = useRef<HTMLDivElement | null>(null);
  const [disableDownButton, setDisableDownButton] = useState(false);
  const [showDownButton, setShowDownButton] = useState(false);

  const [summaryOfChanges, setSummaryOfChanges] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const [dialogShow, setDialogShow] = useState<boolean>(true);
  const [accept, setAccept] = useState(false);
  const [checkedAccept, setCheckedAccept] = useState(false);

  const version = tc.version || '';
  const curDate = moment();
  const consentDeadline = tc.consentDeadline || null;
  const acceptedTs = tcCampaign ? null : tc?.acceptTs ? moment(tc.acceptTs) : null;
  const canAcceptTC = hasPermissionById(PERMISSION_GENERAL) || hasPermissionById(PERMISSION_CHATBOT);

  let deadlineCome = false;
  if (consentDeadline && (curDate > moment(consentDeadline)) && !tcCampaign) {
    deadlineCome = true;
  }

  useEffect(() => {
    const save = async () => {
      setLoading(true);
      const saveParams: TermsConditionsUserInput = {
        termsConditions: tc.id,
        campaign: tcCampaign?.id || null,
      };
      const data = { filter: null, input: saveParams };
      try {
        const { termsConditionsUserSave } = await api.termsConditionsUserSave(data);
        if (termsConditionsUserSave.length && termsConditionsUserSave[0]?.id) {
          setDialogShow(false);
          onClose();
          onAccept();
        } else {
          setError(s('Invalid server response'));
        }
      } catch ({ message }) {
        console.log(message);
        setError(message as string);
      } finally {
        setLoading(false);
        setAccept(false);
      }
    };
    accept && save();
    // eslint-disable-next-line
  }, [accept, tc.id]);

  useEffect(() => {
    if (tc?.tcText) {
      const converter = new showdown.Converter();
      const html = converter.makeHtml(tc.tcText);
      setTcText(html);

      if (tc?.summaryOfChanges) {
        const htmlSum = converter.makeHtml(tc.summaryOfChanges);
        setSummaryOfChanges(htmlSum);
      }

      setTimeout(() => checkShowDownButton(), 100);
    }
  }, [tc]);

  function checkShowDownButton() {
    const el = textRef.current;
    if (el && el.clientHeight === el.scrollHeight) {
      setShowDownButton(false);
    } else {
      setShowDownButton(true);
    }
  }

  const handleTextRef = useCallback((el) => {
    if (el) {
      textRef.current = el;
      checkShowDownButton();
    }
  }, []);

  const arrowButton = (
    <IconButton
      className="material-icons"
      color="primary"
      disabled={disableDownButton}
      title={s('Scroll to the bottom')}
      sx={{
        border: '2px solid',
        borderRadius: '50%',
        width: 50,
        height: 50,
        marginRight: 'auto',
      }}
      onClick={() => {
        if (textRef?.current) {
          textRef.current.scrollTop = textRef.current.scrollHeight;
        }
      }}
    >south
    </IconButton>);

  const title = tcCampaign ? s('Please accept terms and conditions version :ver to start campaign :campaign', { ver: version, campaign: tcCampaign.id })
    : s('Terms and Conditions version :ver', { ver: version });

  return (
    <>
      {error && <Alert className="mb-2" severity="error" onClose={() => setError('')}>{error}</Alert>}
      <Dialog
        disableEscapeKeyDown
        PaperProps={{ className: '', sx: { minHeight: '250px', maxWidth: 'min(840px, 95vw)' } }}
        open={dialogShow}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ padding: 0, flex: 'none' }}>
          {error && <Alert className="" severity="error" onClose={() => setError('')}>{error}</Alert>}
        </DialogContent>
        <DialogContent
          dividers
          ref={handleTextRef}
          className={styles.terms}
          onScroll={(e) => {
            if (((e.target as HTMLElement).scrollTop + (e.target as HTMLElement).clientHeight) >= (e.target as HTMLElement).scrollHeight) {
             setDisableDownButton(true);
            } else {
             setDisableDownButton(false);
            }
          }}
        >
          {loading && <Loading logo={false} size={50} />}
          {tcText && !acceptedTs && (
            canAcceptTC
              ? (
                <>
                  {
                    tcCampaign ? null : (
                      <Typography sx={{ mb: 4 }}>
                        {
                          deadlineCome
                            ? `${s('A new version of Terms and Conditions must be accepted in order to continue using our services')}:`
                            : s('A new version of Terms and Conditions must be accepted until :deadline:', {
                                deadline: <span className="text-primary">{moment(consentDeadline).local()?.format('MMMM D, YYYY, HH:mm ZZ')}</span>,
                            })
                        }
                      </Typography>
                    )
                  }
                  {tcUser?.id
                    && <Typography sx={{ mb: 4 }}>{s('The latest version you accepted is :ver', { ver: version })}</Typography>}
                  {summaryOfChanges
                    && <>
                      <Typography variant="h3" sx={{ mb: 2 }}>{ s('What\'s new in version :ver', { ver: version })}</Typography>
                      {/* eslint-disable-next-line */}
                      <div className={`ms-4 me-2 mb-4-5 ${styles.summaryOfChanges}`} dangerouslySetInnerHTML={{ __html: summaryOfChanges }} />
                    </>}
                  {/* eslint-disable-next-line */}
                  <div className={`${styles.tc_text} ms-1 mb-1`} dangerouslySetInnerHTML={{ __html: tcText }} />
                  <Stack direction="row" spacing={1}>
                    <Switch
                      checked={checkedAccept}
                      onChange={() => setCheckedAccept(!checkedAccept)}
                    />
                    <Typography>{s('I accept the above Terms and Conditions')}</Typography>
                  </Stack>
                </>
              )
              : (
                <div className={styles.notifyText}>
                  {deadlineCome
                    ? `${s('A new version of Terms and Conditions must be accepted in order to continue using our services')}. ${s('Please ask a user with administrative access to accept the new version.')}`
                    : (
                      <>
                        <span>
                          {s('A new version of Terms and Conditions must be accepted until :deadline.', {
                            deadline: <span className="text-primary">{moment(consentDeadline).local().format('MMMM D, YYYY, HH:mm ZZ')}</span>,
                          })}
                        </span>
                          &nbsp;
                        <span>
                          {s('Please ask a user with administrative access to accept the new version.')}
                        </span>
                      </>
                    )}
                </div>
              )
          )}
          { tcText && acceptedTs && (
            // eslint-disable-next-line
            <div className={`${styles.tc_text} ms-1 mb-1`} dangerouslySetInnerHTML={{ __html: tcText }} />
          )}
        </DialogContent>
        <DialogActions>
          {!acceptedTs && (canAcceptTC
            ? (
              <>
                {showDownButton ? arrowButton : null}

                <Button
                  variant="outlined"
                  onClick={() => { // Close or Log out
                    setDialogShow(false);
                    onClose();
                    if (deadlineCome) {
                      appContext.setUser(null);
                      appContext.setToken('');
                      window.location.replace(`${PUBLIC_PATH}login`);
                    }
                  }}
                >
                  {deadlineCome ? s('Log out') : s('Close')}
                </Button>

                {loading
                  ? <Loading logo={false} size={44} />
                  : (
                    <Button
                      variant="contained"
                      disabled={!checkedAccept || !tcText}
                      onClick={() => setAccept(true)}
                    >
                      {s('Accept')}
                    </Button>
                  )}
              </>
            )
            : (
              <>
                {showDownButton ? arrowButton : null}
                <Button
                  variant="outlined"
                  autoFocus
                  onClick={() => {
                    setDialogShow(false);
                    onClose();
                    if (deadlineCome) {
                      appContext.setUser(null);
                      appContext.setToken('');
                      window.location.replace(`${PUBLIC_PATH}login`);
                    }
                  }}
                >
                  {deadlineCome ? s('Log out') : s('Close')}
                </Button>
              </>
            ))}
          {acceptedTs && (
            <>
              {showDownButton ? arrowButton : null}
              <Button
                variant="contained"
                onClick={() => {
                  setDialogShow(false);
                  onClose();
                }}
              >
                {s('Close')}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

