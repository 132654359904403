import { GridColDef } from '@mui/x-data-grid-pro';
import { s } from 'i18n';
import React from 'react';
import { upFirstLetter } from 'shared-scope/helpers/functions';
import NumberField from '@xeebi/neru/dist/react-components/Number';
import DateTime from 'shared-scope/components/DateTime';
import { Balance } from 'graphql-api';
import { snakeCase } from 'lodash';

const COLUMNS: Array<keyof Balance> = [
  'id',
  'availableAmount',
  'postedBalance',
  'holdAmount',
  'creditLimit',
  'expDate',
];

export default function getColumns({ currencySign }: StructProps) {
  const columns: GridColDef[] = COLUMNS.map((field) => {
    let headerName = s(upFirstLetter(snakeCase(field))
      .replace('_', ' ')); // fooBar to Foo bar

    switch (field) {
      case 'id': {
        headerName = s('Description');
      }
    }

    let result: GridColDef = {
      field,
      headerName,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      flex: 0,
    };

    switch (field) {
      case 'availableAmount':
      case 'postedBalance':
      case 'holdAmount':
      case 'creditLimit':
        result = {
          ...result,
          valueGetter: (value) => value || 0,
          renderCell: ({ value }) => (
            <NumberField
              value={value}
              prefix={currencySign || '$'}
              precision={2}
              className={`${value <= 0 ? 'text-danger' : ''}`}
            />
          ),
        };
        break;

      case 'expDate':
        result = {
          ...result,
          valueGetter: (value) => (value ? new Date(value) : null),
          renderCell: ({ value }) => <DateTime value={value} direction="column" />,
        };
        break;
    }

    return result;
  });

  return columns;
}

type StructProps = {
  currencySign: string
};
