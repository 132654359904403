import { IconButton } from '@mui/material';
import { s } from 'i18n';
import { SystemStyleObject } from '@mui/system';
import { MappingParamType } from 'shared-scope/types';

export default function PasteFromClipboard<T>({ options, onPaste, sx }: PasteFromClipboardProps<T>) {
  return navigator.clipboard
    ? (
      <IconButton
        size="small"
        title={s('Paste from clipboard')}
        sx={({ palette }) => ({
          padding: '3px',
          color: palette.primary.main,
          ...sx,
        })}
        onClick={() => navigator.clipboard
          .readText()
          .then((cliptext: string) => {
            const words = (cliptext.match(/\w+/g) || []) as string[];
            const toPaste: string[] = [];
            options.forEach((opt: any) => {
              if (opt.constructor === Object) {
                words.some(
                  (word) => [opt.k.toLowerCase(), opt.v.toLowerCase()].includes(word.toLowerCase()),
                ) && toPaste.push(opt.k);
              } else {
                words.some(
                  (word) => opt.toLowerCase() === word.toLowerCase(),
                ) && toPaste.push(opt);
              }
            });
            onPaste(toPaste as T);
          })}
      ><i className="icon-paste" /></IconButton>
    ) : null;
}

type PasteFromClipboardProps<T> = {
  options: MappingParamType[] | string[]
  onPaste: (value: T) => void
  sx?: SystemStyleObject
};
