import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AxiosRequestConfig } from 'axios';
import { ConversationTemplate } from 'graphql-api';
import { fetcher } from 'graphql-api/fetcher';
import { getSdk as templateGetSdk } from 'products/Templates/query.generated';
import { getSdk } from 'products/CampaignList/query.generated';
import {
  useMountedState,
  useGridRef,
  DataGrid,
  ApiQueryParams,
  ApiQueryResult,
  useRequest,
} from '@xeebi/neru';
import useAlert from 'shared-scope/hooks/useAlert';
import { GridType } from 'products/CampaignList/types';
import { CampaignDetailsProps } from 'products/Details';
import TemplateType from 'products/Templates/types';
import getColumns from './Struct';


const api = getSdk(fetcher);
const apiTemplate = templateGetSdk(fetcher);

const getRecords = async (params: ApiQueryParams, config?: AxiosRequestConfig) => {
  const gridFilter = params.filter === 'null' ? '' : `, ${params.filter}`;
  params.filter = `{"$and":[{"type": 5}${gridFilter}]}`;
  const { campaignCount, campaign } = await api.getVoteSmsCampaigns(params, config);
  return { count: campaignCount, rows: campaign } as ApiQueryResult;
};

const getTemplates = async (data: any, config?: AxiosRequestConfig) => {
  const { conversationTemplate } = await apiTemplate.getConversationTemplate({
    filter: `{"type": ${TemplateType.OptIn}}`,
    sort: '[{"name": 1}]',
  }, config);
  return conversationTemplate;
};

export default function CampaignList() {
  const navigate = useNavigate();
  const gridRef = useGridRef();
  const [templates, setTemplates] = useState<ConversationTemplate[]>([]);
  const { addError } = useAlert();
  const isMounted = useMountedState();

  const {
    error,
    fetch: fetchTemplates,
  } = useRequest(getTemplates);

  useEffect(() => {
    const loadStatuses = async () => {
      const conversationTemplates: ConversationTemplate[] = await fetchTemplates();
      isMounted() && setTemplates(conversationTemplates);
    };
    isMounted() && loadStatuses();
  }, [isMounted, fetchTemplates]);

  useEffect(() => {
    if (error) {
      addError(error.getMessage());
      console.error(error.getError());
    }
  }, [error, addError]);

  const columns = useMemo(() => {
    return getColumns({ gridRef, templates });
  }, [gridRef, templates]);

  return (
    <>
      <DataGrid
        primaryKey="id"
        storageId="OptInCampaignListGrid"
        refreshTimeout={15_000}
        pageSize={20}
        apiRef={gridRef}
        getRows={getRecords}
        columns={columns}
        onRowClick={(row) => {
          navigate(`/opt-in/campaign-list/${row.id}`);
        }}
        nullRender="-"
      />
      <Outlet
        context={{
          gridRef,
          type: GridType.OptIn,
        } as CampaignDetailsProps}
      />
    </>

  );
}
