import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { s } from 'i18n';
import {
  Box,
  Stack,
  Typography,
  Icon,
  Menu,
  MenuItem,
} from '@mui/material';
import {
 DataGrid, ApiQueryParams, useGridRef, FilterDropdown, FilterDateRange,
} from '@xeebi/neru';
import DateTime from 'shared-scope/components/DateTime';
import { GridColDef, GridHeaderFilterCellProps } from '@mui/x-data-grid-pro';
import { Role, User } from 'graphql-api';
import { UserForm, Delete } from '../Components';
import { getRoles, getUsers } from '../helper';


const ADMIN_ROLE_ID = 'admin';

export default function Users() {
  const gridRef = useGridRef();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [openAction, setOpenAction] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useEffect(() => {
    let active = true;
    const loadRoles = async () => {
      try {
        const { role } = await getRoles();
        active && setRoles(role);
      } catch (e) {
        console.log(e);
      }
    };

    loadRoles();

    return () => {
      active = false;
    };
  }, []);

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'action',
      headerName: 'Action',
      renderCell: ({ row }) => (
        <Icon
          sx={{ transform: 'rotate(90deg)' }}
          className="icon-actions"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setOpenAction(true);
            setSelectedUser(row as User);
          }}
        />
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 80,
      flex: 0,
    },
    {
      field: 'name',
      type: 'string',
      headerName: s('Username'),
      disableColumnMenu: true,
      filterable: true,
      editable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'email',
      type: 'string',
      headerName: s('Email'),
      disableColumnMenu: true,
      filterable: true,
      editable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'role',
      headerName: s('Role'),
      disableColumnMenu: true,
      type: 'custom',
      renderCell: ({ value }) => <Typography>{value.map((r: any) => r.name).join(', ')}</Typography>,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDropdown
          {...params}
          options={roles}
          keyName="id"
          valueName="name"
          placeholder={s('roles')}
          minWidth={150}
        />
      ),
      minWidth: 150,
      flex: 0,
    },
    {
      field: 'enabled',
      headerName: s('Enabled'),
      disableColumnMenu: true,
      filterable: false,
      editable: false,
      type: 'boolean',
      minWidth: 124,
      flex: 0,
    },
    {
      field: 'createTs',
      headerName: s('Created'),
      disableColumnMenu: true,
      type: 'custom',
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDateRange
          {...params}
          placeholder={s('Created')}
        />
      ),
      valueGetter: (value) => (value ? new Date(value) : null),
      renderCell: ({ value }) => <DateTime value={value} direction="column" />,
      minWidth: 200,
      flex: 0,
    },
  ], [roles, setAnchorEl]);

  const getData = useCallback(async (params: ApiQueryParams) => {
    const res = await getUsers(params);
    return res;
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
    >
      <DataGrid
        primaryKey="id"
        storageId="UserGridStorage"
        pageSize={12}
        apiRef={gridRef}
        getRows={getData}
        columns={columns}
        onCreate={() => setOpenCreate(true)}
        createLabel="Add new user"
        nullRender="-"
      />
      <Menu
        open={openAction}
        onClose={() => {
          setOpenAction(false);
          setSelectedUser(null);
        }}
        anchorEl={anchorEl}
      >
        <MenuItem
          key="edit"
          onClick={() => setOpenEdit(true)}
        >
          <Stack direction="row" spacing={1}>
            <Icon className="icon-edit" />
            <Typography>{s('Edit')}</Typography>
          </Stack>
        </MenuItem>
        {!selectedUser?.role?.some((r) => r.id === ADMIN_ROLE_ID) && <MenuItem
          key="delete"
          onClick={() => setOpenDelete(true)}
        >
          <Stack direction="row" spacing={1}>
            <Icon className="icon-delete" color="error" />
            <Typography>{s('Delete')}</Typography>
          </Stack>
        </MenuItem>}
      </Menu>
      {/* Create new */}
      <UserForm
        open={openCreate}
        onClose={() => {
          setOpenCreate(false);
          setOpenAction(false);
        }}
        roles={roles}
        onComplete={() => gridRef.current.refresh()}
      />
      {/* Edit */}
      {selectedUser && (
        <UserForm
          open={openEdit}
          onClose={() => {
            setOpenEdit(false);
            setSelectedUser(null);
            setOpenAction(false);
          }}
          roles={roles}
          onComplete={() => gridRef.current.refresh()}
          user={selectedUser}
        />
      )}
      {selectedUser && (
        <Delete
          open={openDelete}
          user={selectedUser}
          onClose={() => {
            setOpenDelete(false);
            setSelectedUser(null);
            setOpenAction(false);
          }}
          onComplete={() => gridRef.current.refresh()}
        />
      )}
    </Box>
  );
}
