import { MappingParamType } from 'shared-scope/types';
import { Combobox } from '@xeebi/neru';
import { Box } from '@mui/material';
import PasteFromClipboard from 'shared-scope/components/PasteFromClipboard';

export default function MappingParamCombobox({
 value, onChange, options, canPaste, inputWidth, multiple,
}: MappingParamComboboxProps) {
  const cbOptions = options.map(
    (opt) => (typeof opt === 'string' ? { k: opt, v: opt } : opt),
  );

  if (multiple) {
    const cbValue = cbOptions.filter(
      (opt) => value && (value as MappingParamComboboxValue[]).includes(opt.k),
    );
    return (
      <Box sx={{ position: 'relative' }}>
        {canPaste
          && <PasteFromClipboard
            sx={{
              position: 'absolute',
              zIndex: 1,
              top: '12px',
              left: '-22px',
            }}
            options={cbOptions}
            onPaste={onChange}
          />}
        <Combobox<MappingParamType, true>
          title=""
          value={cbValue}
          onChange={(e, v) => {
            onChange(v.map((vv) => vv.k));
          }}
          options={cbOptions}
          sx={{ width: inputWidth }}
          optionValue="v"
          optionKey="k"
          multiple
          limitTags={1}
        />
      </Box>
    );
  }
    const cbValue = cbOptions.find((opt) => opt.k === value);
    return (
      <Combobox<MappingParamType>
        title=""
        value={cbValue || null}
        onChange={(e, v) => {
          onChange(v?.k || null);
        }}
        options={cbOptions}
        sx={{ width: inputWidth }}
        optionValue="v"
        optionKey="k"
      />
    );
}

export type MappingParamComboboxOption = MappingParamType | string;
export type MappingParamComboboxValue = string | string[];

type MappingParamComboboxProps = {
  value: MappingParamComboboxValue | MappingParamComboboxValue[]
  onChange: (value: MappingParamComboboxValue | MappingParamComboboxValue[] | null) => void
  options: MappingParamComboboxOption[]
  multiple?: boolean
  canPaste?: boolean
  inputWidth?: number | string
};
