import {
  Grid, TextField, Typography,
} from '@mui/material';
import { s } from 'i18n';
import React, { useEffect, useState } from 'react';
import { Combobox } from '@xeebi/neru';
import { ContactListFilter } from 'products/shared/Campaign/types';

// Added special filters for Birthday greetings
enum BirthdaySpecFilter {
  Before = '$before',
  After = '$after',
  InDay = '$inDay', // for example in1Day filter => { birthday: { $inDay: 1 } }
}

const DATE_FILTERS = [BirthdaySpecFilter.After, BirthdaySpecFilter.Before];

export const BIRTHDAY_INTERVALS: BirthdayInterval[] = [
  {
    k: 'today',
    v: s('Today'),
    dayCount: 0,
    filter: BirthdaySpecFilter.InDay,
  },
  {
    k: 'inAWeek',
    v: s('In a week'),
    dayCount: 7,
    filter: BirthdaySpecFilter.InDay,
  },
  {
    k: 'in3Day',
    v: s('In 3 days'),
    dayCount: 3,
    filter: BirthdaySpecFilter.InDay,
  },
  {
    k: 'in2Day',
    v: s('In 2 days'),
    dayCount: 2,
    filter: BirthdaySpecFilter.InDay,
  },
  {
    k: 'in1Day',
    v: s('In 1 day'),
    dayCount: 1,
    filter: BirthdaySpecFilter.InDay,
  },
  {
    k: 'after',
    v: s('After'),
    filter: BirthdaySpecFilter.After,
  },
  {
    k: 'before',
    v: s('Before'),
    filter: BirthdaySpecFilter.Before,
  },
];


export default function BirthdayFilter({ value, onChange }: BirthdayFilterProps) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [innerFilter, setInnerFilter] = useState<BirthdayInterval | null>(null);

  useEffect(() => {
    const f = Object.keys(value?.birthday || {})?.[0] || null;
    if (f && DATE_FILTERS.includes(f as BirthdaySpecFilter)) {
      setInnerFilter(BIRTHDAY_INTERVALS.find((i) => i.filter === f) || null);
    } else if (f && f === BirthdaySpecFilter.InDay) {
      const d = Number(Object.values(value?.birthday || {})?.[0]);
      setInnerFilter(BIRTHDAY_INTERVALS.find((i) => i.filter === f && i?.dayCount === d) || null);
    } else {
      setInnerFilter(null);
    }
  }, [value]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} lg={4}>
        <Typography>{s('Birthday')}</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Combobox<BirthdayInterval>
          title=""
          value={innerFilter}
          onChange={(e, interval) => {
            if (interval?.filter && DATE_FILTERS.includes(interval.filter)) {
              setShowCalendar(true);
              setInnerFilter(interval);
            } else {
              setShowCalendar(false);
              setInnerFilter(null);
              if (interval?.k) {
                onChange({
                  ...value,
                  birthday: { [interval?.filter]: interval.dayCount },
                });
              } else {
                const filter = { ...value };
                delete filter.birthday;
                onChange(filter);
              }
            }
          }}
          options={BIRTHDAY_INTERVALS}
          sx={{ width: 200 }}
          optionValue="v"
          optionKey="k"
        />
      </Grid>
      {showCalendar && innerFilter
        ? (
          <Grid item xs={12} lg={4} />
        )
        : null}
      {showCalendar && innerFilter
        ? (
          <Grid item xs={12} lg={8}>
            <TextField
              onChange={(e) => {
                if (e.target.value) {
                  const filter = { ...value };
                  onChange({
                    ...filter,
                    birthday: { [innerFilter.filter]: e.target.value },
                  });
                }
              }}
              type="date"
              placeholder="Date"
              sx={{ width: 200 }}
            />
          </Grid>
        )
        : null}
    </Grid>
  );
}

type BirthdayFilterProps = {
  value: ContactListFilter
  onChange: (value: object) => void
};

type BirthdayInterval = {
  k: string
  v: any
  dayCount?: number
  filter: BirthdaySpecFilter
};
