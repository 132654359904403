import React from 'react';
import ReactDOM from 'react-dom';
import './ext.d';
import { Alert, CssBaseline } from '@mui/material';
import * as CONFIG from 'shared-scope/config';
import './main.scss';
import { LicenseInfo } from '@mui/x-license-pro';
import { BrandFiles, getBrandImg } from 'shared-scope/helpers/functions';
import MuiLicenseKey from './MuiLicenseKey.json';
import App from './App';
import Theme from './Theme';
import init from './init';

LicenseInfo.setLicenseKey(MuiLicenseKey);

Promise.all([init()])
  .then(() => {
    /**
     * Set App name and Description
     */
    document.title = CONFIG.get('app_name', 'SMS campaign sender');
    document.querySelector('meta[name="description"]')
      ?.setAttribute('content', CONFIG.get('app_description', 'SMS campaign sender'));

    /**
     * Set favicon
     */
    let linkFavicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!linkFavicon) {
      linkFavicon = document.createElement('link');
      linkFavicon.rel = 'icon';
      document.head.appendChild(linkFavicon);
    }
    linkFavicon.href = getBrandImg(BrandFiles.Favicon);

    /**
     * Set Apple touch icon
     */
    let linkAppleTouch = document.querySelector("link[rel~='apple-touch-icon']") as HTMLLinkElement;
    if (!linkAppleTouch) {
      linkAppleTouch = document.createElement('link');
      linkAppleTouch.rel = 'apple-touch-icon';
      document.head.appendChild(linkAppleTouch);
    }
    linkAppleTouch.href = getBrandImg(BrandFiles.Logo192);
  })
    .then(() => {
        ReactDOM.render(
          <React.StrictMode>
            <Theme>
              <CssBaseline />
              <App />
            </Theme>
          </React.StrictMode>,
          document.getElementById('root'),
        );
    })
    .catch((err) => {
        console.error('Start app error: ', err);
        // Render error
        ReactDOM.render(
          <Alert severity="error">
            {(err.response && err.response.data && err.response.data.message)
              ? err.response.data.message : err.message}
          </Alert>,
         document.getElementById('root'),
        );
    });


