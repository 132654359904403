import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Stack,
  Alert,
} from '@mui/material';
import Loading from 'shared-scope/components/Loading';
import { Route } from 'graphql-api';
import { fetcher } from 'graphql-api/fetcher';
import { getSdk } from 'shared-scope/queries.generated';
import { GridColDef } from '@mui/x-data-grid-pro';
import {
 DataGrid, ApiQueryParams, ApiQueryResult, useGridRef,
} from '@xeebi/neru';
import TagButton from 'shared-scope/components/TagButton';
import { getSdk as rateSdk } from '../query.generated';


const api = getSdk(fetcher);
const apiRate = rateSdk(fetcher);

async function getRates(params: ApiQueryParams, ratePlanId: number) {
  params.filter = params.filter === 'null'
    ? `{"$and":[{"ratePlan.id": ${ratePlanId}}, {"active": true}]}`
    : `{"$and":[{"ratePlan.id": ${ratePlanId}}, {"active": true}, ${params.filter}]}`;
  const { rate, rateCount } = await apiRate.getRates(params);
  return { rows: rate, count: rateCount } as ApiQueryResult;
}

const columns: GridColDef[] = [
  {
    field: 'country.name',
    headerName: 'Country',
    type: 'string',
    minWidth: 150,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    flex: 0,
    valueGetter: (value, row) => row?.country?.name || '',
  },
  {
    field: 'operator.name',
    headerName: 'Operator',
    type: 'string',
    minWidth: 150,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    flex: 0,
    valueGetter: (value, row) => {
      const r: any = row;
      return r?.operator?.name || '';
    },
  },
  {
    field: 'fees.MO',
    headerName: 'Mobile originated',
    type: 'number',
    minWidth: 100,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    flex: 0,
    valueGetter: (value, row) => {
      const r: any = row;
      return r?.fees?.MO || 0;
    },
  },
  {
    field: 'fees.SMS',
    headerName: 'SMS fee',
    type: 'number',
    minWidth: 100,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    flex: 0,
    valueGetter: (value, row) => {
      const r: any = row;
      return r?.fees?.SMS || 0;
    },
  },
  {
    field: 'fees.MT',
    headerName: 'Mobile terminated',
    type: 'number',
    minWidth: 100,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    flex: 0,
    valueGetter: (value, row) => {
      const r: any = row;
      return r?.fees?.MT || 0;
    },
  },
  {
    field: 'fees.ATCH',
    headerName: 'Attachment fee',
    type: 'number',
    minWidth: 100,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    flex: 0,
    valueGetter: (value, row) => {
      const r: any = row;
      return r?.fees?.ATCH || 0;
    },
  },
];

export default function Prices() {
  const gridRef = useGridRef();
  const [routes, setRoutes] = useState<Route[]>([]);
  const [loading, setLoading] = useState(false);
  const [ratePlanId, setRatePlanId] = useState<number | null>(null);
  const [error, setError] = useState('');

  const getData = useCallback(async (params: ApiQueryParams) => {
    if (ratePlanId) {
      const result = await getRates(params, ratePlanId);
      return result;
    }
    return { rows: [], count: 0 } as ApiQueryResult;
    // eslint-disable-next-line
  }, [ratePlanId]);

  useEffect(() => {
    gridRef.current.refresh?.();
  }, [getData, gridRef]);

  useEffect(() => {
    let active = true;
    const loadData = async () => {
      setLoading(true);
      try {
        const { route } = await api.getRoutes();
        if (active && route.length > 0) {
          setRoutes(route);
          if (route.length === 1) {
            setRatePlanId(route[0].ratePlan?.id || null);
          }
        }
      } catch ({ message }) {
        console.log(message);
        setError(message as string);
      } finally {
        setLoading(false);
      }
    };

    loadData();

    return () => {
      active = false;
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
    >
      <Stack spacing={2} sx={{ overflow: 'auto' }}>
        {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}

        {loading
          ? <Loading logo={false} size={150} />
          : (
            <Stack
              spacing={1}
              direction="row"
              flexWrap="wrap"
              useFlexGap
            >
              {routes.map((r) => (
                <TagButton
                  onClick={() => setRatePlanId(r.ratePlan?.id || null)}
                  isActive={r.ratePlan?.id === ratePlanId}
                >
                  {r.name}
                </TagButton>
              ))}
            </Stack>
          )}

        {ratePlanId
          ? (
            <DataGrid
              primaryKey="id"
              storageId="RouteGrid"
              pageSize={15}
              columns={columns}
              getRows={getData}
              apiRef={gridRef}
              enableHeaderFilters={false}
              slots={{
                toolbar: () => null,
              }}
              nullRender="-"
            />
          )
          : null}
      </Stack>
    </Box>
  );
}
