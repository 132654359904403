import React, { useEffect, useMemo, useState } from 'react';
import { s } from 'i18n';
import { AxiosRequestConfig } from 'axios';
import { Stack } from '@mui/material';
import {
 ApiQueryParams, ApiQueryResult, DataGrid, useGridRef, useRequest,
} from '@xeebi/neru';
import TagButton from 'shared-scope/components/TagButton';
import { useAppContext } from 'shared-scope/AppContext';
import { fetcher } from 'graphql-api/fetcher';
import { Module, TrxType } from 'graphql-api';
import useAlert from 'shared-scope/hooks/useAlert';
import { DataGridProps } from '@xeebi/neru/dist/react-components/MuiDataGrid/types';
import { getSdk } from './query.generated';
import getTrxColumns from './TrxStruct';
import getDocColumns from './DocStruct';
import getBalancesColumns from './BalancesStruct';

const api = getSdk(fetcher);

const TAB_KEYS = ['Balances', 'Transactions', 'Documents'] as const;
type TTabKey = typeof TAB_KEYS[number];

export const getBalances = async (params: ApiQueryParams, config: AxiosRequestConfig) => {
  const { balance, balanceCount } = await api.getBalances(params, config);

  return { count: balanceCount, rows: balance } as ApiQueryResult;
};

export const getTrx = async (params: ApiQueryParams, config: AxiosRequestConfig) => {
  const { trx, trxCount } = await api.getTrx(params, config);
  return { count: trxCount, rows: trx } as ApiQueryResult;
};

export const getInvoices = async (params: ApiQueryParams, config: AxiosRequestConfig) => {
  const { invoice, invoiceCount } = await api.getInvoices(params, config);
  return { count: invoiceCount, rows: invoice } as ApiQueryResult;
};

export default function Billing() {
  const gridRef = useGridRef();
  const { addError } = useAlert();
  const { currencySign } = useAppContext();

  const [trxTypes, setTrxTypes] = useState<TrxType[]>([]);
  const [modules, setModules] = useState<Module[]>([]);
  const [tab, setTab] = useState<TTabKey>(TAB_KEYS[0]);

  const { error: trxTypeError, fetch: fetchTrxTypes } = useRequest(api.getTrxType);
  const { error: moduleError, fetch: fetchModules } = useRequest(api.getModules);

  const trxColumns = useMemo(() => {
    return getTrxColumns({ trxTypes, currencySign, modules });
  }, [trxTypes, currencySign, modules]);

  const docColumns = useMemo(() => {
    return getDocColumns({ currencySign });
  }, [currencySign]);

  const balancesColumns = useMemo(() => getBalancesColumns({ currencySign }), [currencySign]);

  useEffect(() => {
    const getTypes = async () => {
      const data = await fetchTrxTypes();
      data?.trxType && setTrxTypes(data?.trxType);
    };
    const getModules = async () => {
      const data = await fetchModules();
      data?.module && setModules(data?.module);
    };
    getTypes();
    getModules();
  }, [fetchModules, fetchTrxTypes]);

  useEffect(() => {
    const error = moduleError || trxTypeError;
    trxTypeError && addError(error.getMessage());
  }, [trxTypeError, moduleError, addError]);

  const gridProps = useMemo(() => {
    let result: Partial<DataGridProps<any>> & Pick<DataGridProps<any>, 'storageId' | 'columns' | 'getRows' | 'primaryKey'>;

    switch (tab) {
      case 'Balances':
        result = {
          primaryKey: 'id',
          storageId: 'BillingBalanceStorage',
          columns: balancesColumns,
          getRows: getBalances,
        };
        break;
      case 'Transactions':
        result = {
          primaryKey: 'id',
          storageId: 'BillingTrxStorage',
          columns: trxColumns,
          getRows: getTrx,
          rowHeight: 50,
        };
        break;
      case 'Documents':
        result = {
          primaryKey: 'id',
          storageId: 'BillingDocStorage',
          columns: docColumns,
          getRows: getInvoices,
        };
    }

    result.enableHeaderFilters = result.columns.some((c) => c.filterable);

    return result;
  }, [tab, trxColumns, docColumns, balancesColumns]);

  return (
    <Stack spacing={2}>
      <Stack
        spacing={1}
        direction="row"
        flexWrap="wrap"
        useFlexGap
      >
        {TAB_KEYS.map((key) => (
          <TagButton
            key={key}
            onClick={() => setTab(key)}
            isActive={tab === key}
          >
            {s(key)}
          </TagButton>
        ))}
      </Stack>
      <DataGrid
        key={tab}
        pageSize={20}
        apiRef={gridRef}
        nullRender="-"
        {...gridProps}
      />
    </Stack>
  );
}
