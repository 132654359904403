import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Stack,
} from '@mui/material';
import {
  GridColDef,
  GridHeaderFilterCellProps,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { AxiosRequestConfig } from 'axios';
import { s } from 'i18n';
import { fetcher } from 'graphql-api/fetcher';
import { StopList as StopListType } from 'graphql-api';
import useAlert from 'shared-scope/hooks/useAlert';
import DateTime from 'shared-scope/components/DateTime';
import { phoneNumberFormat } from 'shared-scope/helpers/functions';
import { CAMPAIGN_TYPE_OPT_IN } from 'products/common/helpers';
import Page from 'layout/Page';
import {
  DataGrid,
  ApiQueryParams,
  ApiQueryResult,
  useGridRef,
  useRequest,
  FilterDateRange,
  useMountedState,
} from '@xeebi/neru';
import { getSdk } from 'products/StopList/query.generated';
import exportStopList from './helpers';


const api = getSdk(fetcher);

const columns: GridColDef[] = [
  {
    field: 'phoneNumber',
    headerName: 'Phone number',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 140,
    flex: 0,
    valueFormatter: (value) => phoneNumberFormat(value),
  },
  {
    field: 'countryName',
    headerName: 'Country',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 140,
    flex: 0,
  },
  {
    field: 'operatorName',
    headerName: 'Operator',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 140,
    flex: 0,
  },
  {
    field: 'campaignName',
    headerName: 'Campaign',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) => {
      const data = row as StopListType;
      let path: string;
      if (data.campaign?.type === CAMPAIGN_TYPE_OPT_IN) {
        path = '/opt-in/campaign-list';
      } else {
        path = data.campaign?.conversationTemplate?.questions?.length > 0 ? '/poll' : '/campaign';
      }
      return data.campaign ? (
        <Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
          <NavLink to={`${path}/${data.campaign?.id}`}>
            <Typography>{data.campaignName}</Typography>
          </NavLink>
        </Stack>
      ) : '-';
    },
  },
  {
    field: 'replyText',
    headerName: 'Message',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 140,
    flex: 1,
  },
  {
    field: 'timestamp',
    headerName: s('Timestamp'),
    disableColumnMenu: true,
    type: 'custom',
    renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
      <FilterDateRange
        {...params}
        placeholder={s('Start date')}
      />
    ),
    valueGetter: (value) => (value ? new Date(value) : null),
    renderCell: ({ value }) => <DateTime value={value} direction="row" />,
    minWidth: 190,
    flex: 0,
  },
];

const deleteStopListRecords = async (selected: GridRowSelectionModel, config?: AxiosRequestConfig) => {
  await api.stopListDelete({
    filter: JSON.stringify({ id: { $in: selected } }),
  }, config);
};

export default function StopList() {
  const gridRef = useGridRef();
  const isMounted = useMountedState();
  const [count, setCount] = useState(0);
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const {
    isLoading: exportPending,
    error: exportError,
    fetch: fetchExport,
    cancel: cancelExport,
  } = useRequest(exportStopList);
  const { addError } = useAlert();

  const getData = useCallback(async (params: ApiQueryParams, config?: AxiosRequestConfig) => {
    const { stopListCount, stopList } = await api.getStopList(params, config);
    isMounted() && setCount(stopListCount || 0);
    return { count: stopListCount, rows: stopList } as ApiQueryResult;
  }, [isMounted]);

  const getExport = useCallback(async () => {
    if (exportPending) {
      cancelExport(s('Duplicate request'));
    }
    const filter = gridRef.current.mangoFilter || null;
    await fetchExport(filter, {});
  }, [exportPending, fetchExport, cancelExport, gridRef]);

  const {
    isLoading: deletePending,
    error: deleteError,
    fetch: fetchDelete,
    cancel: cancelDelete,
  } = useRequest(deleteStopListRecords);

  const deleteRecords = useCallback(async () => {
    if (deletePending) {
      cancelDelete(s('Duplicate request'));
    }
    await fetchDelete(selected);
    setSelected([]);
    gridRef.current.refresh();
  }, [deletePending, fetchDelete, cancelDelete, selected, gridRef]);

  useEffect(() => {
    if (exportError) {
      addError(exportError.getMessage());
      console.error(exportError.getError());
    }
    if (deleteError) {
      addError(deleteError.getMessage());
      console.error(deleteError.getError());
    }
  }, [exportError, addError, deleteError]);

  const ExportTool = useCallback(() => (
    <Stack
      direction="row"
      spacing={1}
      sx={{ marginLeft: 'auto', p: 1 }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography>{`${s('Contacts')}: ${count.toLocaleString('en-US')}`}</Typography>
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        sx={{ fontSize: '1rem' }}
        onClick={() => getExport()}
      >
        {s('Export')}
      </Button>
    </Stack>
  ), [getExport, count]);

  return (
    <Page title={s('Stop list')}>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          primaryKey="id"
          storageId="StopListGrid"
          refreshTimeout={15_000}
          pageSize={20}
          apiRef={gridRef}
          getRows={getData}
          columns={columns}
          customTools={ExportTool}
          selected={selected}
          onSelect={setSelected}
          onDelete={deleteRecords}
          checkboxSelection
          hideFooterSelectedRowCount={false}
          nullRender="-"
        />
      </Box>
    </Page>
  );
}

